import { cn } from '@/utils/tailwind';
import {
  Checkbox as RACCheckbox,
  CheckboxProps as RACCheckboxProps,
  CheckboxRenderProps,
} from 'react-aria-components';

type CheckboxProps = RACCheckboxProps & {
  checkboxClassName?: string;
};

export function Checkbox({ children, className, ...props }: CheckboxProps) {
  return (
    <RACCheckbox
      className={cn(
        'group flex flex-auto items-center',
        props.isDisabled && 'text-neutral-500',
        className
      )}
      {...props}
    >
      {(checkboxState) => {
        return (
          <>
            <div
              className={cn(
                checkboxClass(checkboxState),
                props.checkboxClassName
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3" viewBox="0 0 18 18">
                <polyline
                  points="1 9 7 14 15 4"
                  fill="none"
                  stroke="white"
                  strokeWidth={3}
                  strokeDasharray={22}
                  strokeDashoffset={checkboxState.isSelected ? 44 : 66}
                  style={{
                    transition: 'all 400ms',
                  }}
                />
              </svg>
            </div>
            {children}
          </>
        );
      }}
    </RACCheckbox>
  );
}

function checkboxClass(state: CheckboxRenderProps) {
  return cn(
    'border-2 rounded border-neutral-400 group-active:border-neutral-400',
    'w-[16px] h-[16px] mr-2 mt-[3px] bg-white text-white',
    'flex flex-shrink-0 justify-center items-center self-start',
    'shadow-outline transition ease-in-out duration-150',
    state.isSelected &&
      'bg-primary group-active:bg-primary-600 border-primary group-active:border-primary',
    state.isFocused &&
      'outline-none ring-2 ring-slate-400 ring-offset-2 dark:border-slate-700 dark:text-slate-100 dark:hover:text-slate-900 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900',
    state.isDisabled && 'border-neutral-300'
  );
}
