'use client';

import { VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { Button, ButtonProps as RacButtonProps } from 'react-aria-components';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';

import useComponentConfig from '@/hooks/config/use-component-config';
import { borderOutlineCva, buttonVariants } from './button-variants';

export interface ButtonProps
  extends RacButtonProps,
    VariantProps<typeof buttonVariants> {
  /**
   * show icon, you control the icon position with the "icon" prop variant
   */
  iconElement?: React.ReactNode;
  /**
   * this property should use as the escape hatch only
   * if there are any cases the default button variants don't cover
   * please reach out to the team and designers to discuss
   */
  className?: RacButtonProps['className'];
  form?: string;
  wrapperClassName?: string;
  childrenClassName?: string;
}

function DsButton(
  {
    className,
    iconElement,
    children,
    isDisabled,
    isLoading,
    ...props
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  /**
   * we only support fixed size icon
   */
  const iconEle = iconElement ? (
    // the icon size will be controlled by the consumer
    <span className="flex items-center justify-center">{iconElement}</span>
  ) : null;

  const loadingIcon = (
    <span className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
      <Icon name="circle-notch" className="animate-spin" />
    </span>
  );

  const buttonsConfig = useComponentConfig('buttons');
  return (
    <Button
      {...props}
      isDisabled={isDisabled || !!isLoading}
      className={(renderProps) => {
        let _className = undefined;
        if (className) {
          _className =
            typeof className === 'string' ? className : className(renderProps);
        }

        return cn(
          buttonVariants({
            variant: props.variant,
            size: props.size,
            icon: props.icon,
            fullWidth: props.fullWidth,
            textCenter: props.textCenter,
            noBorder: props.noBorder,
            isSticky: props.isSticky,
            customPadding: !!buttonsConfig?.config?.padding,
          }),
          borderOutlineCva({
            outline: renderProps.isFocused || renderProps.isFocusVisible,
          }),
          _className,
        );
      }}
      ref={ref}
    >
      {(values) => {
        return (
          // Added `wrapperClassName` to have more styling control on the child wrapper
          // Duc will revisit this to make sure it is okay
          // Added `childrenClassName` to have more styling control on the children

          <span
            className={cn(
              'pointer-events-none relative',
              // Apply inline-flex to icons (when not loading) to centralise them.
              //
              isLoading ? '' : 'inline-flex',
              props.wrapperClassName,
            )}
          >
            {props.icon === 'iconLeft' ? iconEle : null}
            {isLoading && loadingIcon}
            <span
              className={cn(
                {
                  'w-0 text-transparent': isLoading,
                },
                props.childrenClassName,
              )}
            >
              {typeof children === 'function' ? children(values) : children}
            </span>
            {props.icon === 'iconRight' ? iconEle : null}
          </span>
        );
      }}
    </Button>
  );
}

const _Button = forwardRef(DsButton);
export { _Button as Button };
