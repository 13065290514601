// This component is used to wrap the Drawer component with
// <DialogTrigger isOpen={true | false} as its value to open or close the Drawer.
// This will handle the Drawer's state and its other behaviors such as closing the Drawer when clicking outside the Drawer.

import { ReactNode } from 'react';
import { FocusScope, useKeyboard } from 'react-aria';

export interface ControlledDrawerWrapperProps {
  children: ReactNode;
  onClose: () => void;
}

export function ControlledDrawerWrapper({
  children,
  onClose,
}: ControlledDrawerWrapperProps) {
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
  });

  return (
    <div {...keyboardProps}>
      <FocusScope autoFocus>{children}</FocusScope>
    </div>
  );
}
