import { cn } from '@/utils/tailwind';
import { memo } from 'react';

const sparkleIconSrc = '/assets/icon/chat-bot/sparkle.svg';

export function Component({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border border-solid border-neutral-400',
        className,
      )}
    >
      <img src={sparkleIconSrc} height={16} width={16} alt="" />
    </div>
  );
}

export const ChatBotAvatar = memo(Component);
