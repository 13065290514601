import type { LocalePrefix } from 'next-intl/dist/types/src/routing/types';

import type { Locale, LocaleWithISO639Prefix } from './types';

export const defaultLocale = 'en-US' as const satisfies LocaleWithISO639Prefix;

export const localePrefix = { mode: 'always' } satisfies LocalePrefix;

/** NOTE(rongsen): To be enabled in Q4. */
// const _futureLocales = [
//   'ja-JP',
// ] as const satisfies readonly LocaleWithISO639Prefix[];

export const supportedLocales = [
  defaultLocale,
  'pt-BR',
  'zh-CN',
  'id-ID',
  'zh-HK',
  'es-ES',
  'en-GB',
  'en-AU',
  // ...futureLocales /** NOTE(rongsen): To be enabled in Q4. */
] as const satisfies readonly LocaleWithISO639Prefix[];

/**
 * NOTE(sontruong):
 * Labels source: https://www.notion.so/kaligo/Configure-language-support-c7536a90bed0411c85e4c14fcb0a9693#a54aed335e1b4048b8fcbaf2a468c19c
 */
export const localeLabels = {
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  'en-AU': 'English (AU)',
  'pt-BR': 'Português (BR)',
  'id-ID': 'Bahasa Indonesia',
  'zh-CN': '简体中文',
  'zh-HK': '簡體中文',
  'es-ES': 'Español',
} as const satisfies Record<Locale, string>;

export const symbolCookieL10n = 'NEXT_L10N' as const;

export const symbolHeaderAcceptLanguage = 'x-accept-language' as const;

export const symbolHeaderDefaultLocale = 'x-default-locale' as const;

export const symbolHeaderLocales = 'x-locales' as const;

/**
 * NOTE(sontruong):
 * This regex pattern is used to match a locale after extracting it from the pathname of a URL.
 *
 * The general approach:
 * localeRegex.test(new URL(url).pathname.split('/')[1])
 *
 * @example
 * // Example 1: Valid locale extraction
 * const url = 'https://abc.com/zh-CN?test=test';
 * const locale = new URL(url).pathname.split('/')[1];
 * console.log(locale); // 'zh-CN'
 * console.log(localeRegex.test(locale)); // true
 *
 * @example
 * // Example 2: Invalid locale
 * const url = 'https://abc.com/help-center#section-1';
 * const locale = new URL(url).pathname.split('/')[1];
 * console.log(locale); // 'help-center'
 * console.log(localeRegex.test(locale)); // false
 *
 * @example
 * // Valid locale cases (✅):
 * localeRegex.test('zh-CN') // true
 * localeRegex.test('en-US') // true
 * localeRegex.test('pt-BR') // true
 *
 * @example
 * // Invalid locale cases (❌):
 * localeRegex.test('') // false
 * localeRegex.test(undefined) // false
 * localeRegex.test('en-USAbc') // false
 */
export const localeRegex = /^([a-z]{2}-[A-Z]{2})$/;
