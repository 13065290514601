'use client';

import { Key, ReactNode, useContext } from 'react';
import { useIsSSR } from 'react-aria';
import type { ListBoxItemProps, SelectProps } from 'react-aria-components';
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectStateContext,
  SelectValue,
} from 'react-aria-components';
import ReactDOM from 'react-dom';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

import Drawer, { DrawerBody, DrawerHeader } from '../drawer/drawer';

// Portal to nowhere
const hiddenFragment =
  typeof DocumentFragment !== 'undefined' ? new DocumentFragment() : null;

// Replicate Hidden component from RAC
// so that we can hide SelectItem from view
// but the Select component can still query it
export function Hidden(props: { children: ReactNode }) {
  let isSSR = useIsSSR();

  // In SSR, portals are not supported by React. Instead, render into a <template>
  // element, which the browser will never display to the user. In addition, the
  // content is not part of the DOM tree, so it won't affect ids or other accessibility attributes.
  return isSSR ? (
    <template data-react-aria-hidden>{props.children}</template>
  ) : (
    ReactDOM.createPortal(props.children, hiddenFragment!)
  );
}

// We must force input id prop
// so that we can keep selected item or else
// RAC will regenerate a new id on any screen size change
// Still trying to figure out how RAC Select generate new id every time
export function SelectItem(
  props: ListBoxItemProps & {
    children: React.ReactNode;
    id: Key;
    listBoxClassName?: string;
  },
) {
  return (
    <ListBoxItem
      {...props}
      className={cn(
        'cursor-default hover:cursor-pointer',
        'flex items-center',
        'rounded',
        'my-[2px] p-2',
        'text-black',
        'outline-none',
        'focus:bg-primary-200 hover:bg-primary-200',
        'focus:outline-offset-0',
        props.listBoxClassName,
      )}
    >
      {({ isSelected }) => (
        <span
          className={cn({
            'font-bold text-primary': isSelected,
          })}
        >
          {props.children}
        </span>
      )}
    </ListBoxItem>
  );
}

function SelectIcon() {
  const selectStateContext = useContext(SelectStateContext);

  return (
    <Icon
      className={cn('h-4 w-4', 'fill-neutral-600 group-hover:fill-black', {
        'rotate-180 fill-black': selectStateContext.isOpen,
      })}
      name="chevron-down"
    />
  );
}

interface DsSelectProps<T extends object>
  extends Omit<SelectProps<T>, 'children'> {
  labelClassName?: string;
  listBoxClassName?: string;
  placeholderClassName?: string;
  popOverClassName?: string;
  triggerClassName?: string;
  label?: string;
  hideScrollBar?: boolean;
  additionalValueText?: string;
  children: ReactNode | ((item: T) => ReactNode);
  items?: T[];
  triggerField?: () => JSX.Element;
  usePopoverOnMobile?: boolean;
}

export default function DsSelect<
  T extends
    | object
    | { value: Key; label: string }
    | { value: Key; name: string },
>({
  label,
  items,
  children,
  labelClassName,
  placeholderClassName,
  popOverClassName,
  listBoxClassName,
  triggerClassName,
  hideScrollBar,
  additionalValueText,
  triggerField,
  usePopoverOnMobile,
  ...props
}: DsSelectProps<T>) {
  const isMobile = useIsMobile();

  const itemList = (
    <ListBox
      className={cn(
        'max-h-[300px] overflow-y-auto px-1 outline-none',
        hideScrollBar ? 'no-scrollbar' : '',
        listBoxClassName,
      )}
      items={items}
    >
      {children}
    </ListBox>
  );

  const selectedItem = items
    ? items.find((item) => {
        if ('value' in item && item.value === props.selectedKey) {
          return true;
        }
        return false;
      })
    : null;

  return (
    <Select
      //Warning: If you do not provide a visible label, you must specify an aria-label or aria-labelledby attribute for accessibility
      // TODO: force the consumer set the aria-label  https://kaligo.atlassian.net/browse/RPD-409
      aria-label="default label"
      {...props}
    >
      {triggerField ? (
        triggerField()
      ) : (
        <>
          {label ? (
            <Label className={cn('cursor-default', labelClassName)}>
              {label}
            </Label>
          ) : null}
          <Button
            className={cn(
              'flex items-center justify-between gap-4',
              'px-4 py-2',
              'rounded border border-neutral-400 focus:border-black hover:border-black',
              'focus-link aria-expanded:border-black aria-expanded:shadow-focus',
              'bg-white',
              'min-w-[280px]',
              'group',
              triggerClassName,
            )}
          >
            <SelectValue
              className={cn(
                'data-[placeholder=true]:text-neutral-400',
                placeholderClassName,
              )}
            >
              {selectedItem ? (
                <>
                  {additionalValueText ? <>{additionalValueText} </> : null}
                  {'label' in selectedItem
                    ? selectedItem.label
                    : 'name' in selectedItem
                    ? selectedItem.name
                    : null}
                </>
              ) : null}
            </SelectValue>
            <SelectIcon />
          </Button>
        </>
      )}

      {isMobile && !usePopoverOnMobile ? (
        <>
          {/**
           * We must render the hidden list of items (this will not appear on DOM)
           * just for the Select component to query it when the Drawer is not open
           * so that we can toggle the Select component. This doesn't need for Popover
           * since Popover has it own hidden list of items.
           *
           * https://github.com/adobe/react-spectrum/blob/5240d4dbc58794072c0508217ced575330f483f7/packages/%40react-stately/select/src/useSelectState.ts#L62C9-L62C9
           */}
          <Hidden>{itemList}</Hidden>
          <Drawer>
            <DrawerHeader title={label} />
            <DrawerBody>{itemList}</DrawerBody>
          </Drawer>
        </>
      ) : (
        <Popover
          className={cn(
            'entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out',
            'w-[--trigger-width] overflow-auto',
            'px-2',
            'shadow-elevation-low',
            'rounded',
            'bg-white',
            popOverClassName,
          )}
        >
          {itemList}
        </Popover>
      )}
    </Select>
  );
}
