import { AscendaLogo } from '@/components/logo';
import { FooterLink } from '../footer-link/footer-link';
import { brandLogoLinkDisplayName } from './constants';
import type { BrandLogoLinkProps } from './types';

export function BrandLogoLink({ className, logo }: BrandLogoLinkProps) {
  return (
    <FooterLink className={className} href="/">
      {logo ? (
        <span>
          <img src={logo} className="max-h-[64px] max-w-[160px]" alt="logo" />
        </span>
      ) : (
        <AscendaLogo className="h-6 w-auto" role="graphics-document" />
      )}
    </FooterLink>
  );
}

BrandLogoLink.displayName = brandLogoLinkDisplayName;
