import { cn } from '@/utils/tailwind';
import { forwardRef } from 'react';
export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps & { isError?: boolean }
>(({ className, ...props }, ref) => {
  const { isError } = props;

  return (
    <textarea
      className={cn(
        'flex h-20 w-full rounded',
        'px-3 py-2 text-sm',
        'border border-slate-300 bg-transparent',
        'placeholder:text-slate-400',
        'focus-link outline-offset-0 focus:border-black',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900',
        isError ? 'border-error' : '',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
