'use client';
import { useSize } from 'ahooks';
import { useEffect, useState } from 'react';

/**
 * @description detect mobile view port
 * @deprecated use tailwindcss instead for example: className="hide lg:block"
 * @returns boolean is mobile screen
 */
export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const isClient = typeof window !== 'undefined';
  const size = useSize(isClient ? document.body : undefined);

  useEffect(() => {
    const width = size?.width || Number.MAX_VALUE;
    setIsMobile(width < 1024);
  }, [size]);

  return isMobile;
}
