'use client';

import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';

import {
  generateColorPalette,
  generateOverrideColor,
  generateOverrideThemeStyles,
} from '@/utils/color-utils';
import { useEffect } from 'react';

export default function TenantHubConfigExtractorProvider() {
  const tenantHubConfig = useTenantHubConfig();

  useEffect(() => {
    if (!tenantHubConfig) return;

    const {
      primary_color: primaryColor,
      header_color: headerColor,
      header_font_color: headerFontColor,
      footer_font_color: footerFontColor,
    } = tenantHubConfig;
    requestAnimationFrame(() => {
      if (primaryColor) {
        generateOverrideThemeStyles('primary', primaryColor);
      }

      if (headerColor) {
        generateOverrideColor('--rc-header--bg-color', headerColor);
        generateColorPalette('header', headerColor);
      }

      if (headerFontColor) {
        generateOverrideColor('--rc-header--color', headerFontColor);
        generateOverrideColor('--rc-menu-item--color', headerFontColor);
        generateOverrideColor(
          '--rc-menu-item--description-color',
          headerFontColor,
        );
        generateOverrideColor(
          '--rc-menu-item--icon-fill-color',
          headerFontColor,
        );
      }

      if (footerFontColor) {
        generateOverrideColor('--rc-color-footer', footerFontColor);
        generateColorPalette('footer', footerFontColor);
      }
    });
  }, [tenantHubConfig]);

  return null;
}
