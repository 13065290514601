import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { cn } from '@/utils/tailwind';

export const Container = forwardRef(
  (
    {
      className,
      ...props
    }: PropsWithChildren<{
      tabIndex?: number;
      className?: string;
      style?: React.CSSProperties;
    }>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={cn('container-responsive', className)}
        {...props}
      />
    );
  },
);

Container.displayName = 'Container';
