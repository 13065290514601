export function buttonStyles(config: {
  variant: string;
  borderRadius: string;
  borderWidth: string;
}): string {
  return `
    --rc-btn-${config.variant}-border-radius: ${config.borderRadius};
    --rc-btn-${config.variant}-border-width: ${config.borderWidth};
  `;
}
