import { sendGTMEvent } from '@/root-layout-config/nextjs-gtm';
import { useVariant } from '@unleash/nextjs';
import { useEffect } from 'react';
import { Flag } from '../flags-ready/flags';

export const useGTMTenantReady = (
  tenantId: string | undefined,
  userId: string | undefined,
) => {
  useEffect(() => {
    if (tenantId) {
      sendGTMEvent({
        event: 'tenant_ready',
        tenant_id: tenantId,
      });
    }
  }, [tenantId]);

  useEffect(() => {
    if (userId) {
      sendGTMEvent({
        event: 'tenant_ready',
        user_id: userId,
      });
    }
  }, [userId]);

  const teSingleSupplierExperiment = useVariant(
    Flag.TESingleSupplierExperiment,
  );

  useEffect(() => {
    if (teSingleSupplierExperiment?.enabled) {
      sendGTMEvent({
        event: 'tenant_ready',
        experiment_id: [
          `${Flag.TESingleSupplierExperiment}=${teSingleSupplierExperiment.name}`,
        ].join('; '),
      });
    }
  }, [teSingleSupplierExperiment?.name]);
};
