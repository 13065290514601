import { getClientUrl } from '@/utils/get-client-url';
import { useMutation } from '@tanstack/react-query';
import { useAuthFetchKy } from '../fetch/use-auth-fetch-ky';

export function useCleanChatBot() {
  const baseUrl = getClientUrl();
  const authFetch = useAuthFetchKy;
  return useMutation({
    mutationFn: () => {
      return authFetch(`${baseUrl}/api/chats/archive`, {
        method: 'POST',
      });
    },
  });
}
