'use client';
import { UnexpectedErrorDialog } from '@/components/unexpected-error-dialog';
import { Todo } from 'global';
import { createContext, ReactNode, useState } from 'react';

type UnexpectedErrorSetting =
  | {
      unexpectedError: string | null;
      buttonText?: string | null;
      htmlContent?: never;
    }
  | {
      unexpectedError?: never;
      buttonText?: string | null;
      htmlContent: string;
    }
  | boolean;

const getValueFromSetting = (
  unexpectedErrorSetting: UnexpectedErrorSetting,
  valueName: 'unexpectedError' | 'buttonText' | 'htmlContent',
) => {
  if (typeof unexpectedErrorSetting === 'boolean') {
    return null;
  }
  return unexpectedErrorSetting[valueName] || null;
};

export const UnexpectedErrorContextSetting = createContext<{
  unexpectedErrorSetting: UnexpectedErrorSetting;
  setUnexpectedErrorSetting: (errorSetting: UnexpectedErrorSetting) => void;
  setUnexpectedErrorOnClose: Todo;
}>({
  unexpectedErrorSetting: { unexpectedError: null, buttonText: null },
  setUnexpectedErrorSetting: (errorSetting: UnexpectedErrorSetting) => {},
  setUnexpectedErrorOnClose: () => {},
});

export function UnexpectedErrorSettingProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [unexpectedErrorSetting, setUnexpectedErrorSetting] =
    useState<UnexpectedErrorSetting>(false);
  const [unexpectedErrorOnClose, setUnexpectedErrorOnClose] = useState<
    (() => void) | null
  >(null);

  return (
    <UnexpectedErrorContextSetting.Provider
      value={{
        unexpectedErrorSetting,
        setUnexpectedErrorSetting,
        setUnexpectedErrorOnClose,
      }}
    >
      <>
        <UnexpectedErrorDialog
          open={!!unexpectedErrorSetting}
          // This means that sending `setUnexpectedErrorSetting(true)` shows the generic error message
          message={getValueFromSetting(
            unexpectedErrorSetting,
            'unexpectedError',
          )}
          htmlContent={getValueFromSetting(
            unexpectedErrorSetting,
            'htmlContent',
          )}
          buttonText={getValueFromSetting(unexpectedErrorSetting, 'buttonText')}
          onClose={() => {
            if (unexpectedErrorOnClose) {
              unexpectedErrorOnClose();
              setUnexpectedErrorOnClose(null);
            }
            setUnexpectedErrorSetting(false);
          }}
        />
        {children}
      </>
    </UnexpectedErrorContextSetting.Provider>
  );
}
