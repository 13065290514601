import { flightRoutes } from '@/app/[locale]/travel/flights/routes';
import camelcase from 'camelcase';
import { useMemo } from 'react';
import styles from './chat-bot-message.module.css';

// book flight to CXR tmr for 1pax

export function ChatBotMessage({ message }: { message: string }) {
  const myEl = useMemo(() => {
    const el = document.createElement('div');

    el.innerHTML = message;
    const aTags = el.querySelectorAll('a');

    aTags.forEach((a) => {
      const { pathname, queryParams } = parseUrl(a.href);

      if (pathname === '/travel/flights/results') {
        console.log('query params', queryParams);
        a.setAttribute(
          'href',
          `${flightRoutes.results}?${new URLSearchParams(queryParams)}`,
        );
      }
    });

    return el;
  }, [message]);

  return (
    <div
      className={styles.messageRoot}
      dangerouslySetInnerHTML={{ __html: myEl.innerHTML }}
    ></div>
  );
}

function parseUrl(url: string) {
  // Split the URL into the path and query string
  const parsedUrl = new URL(url);
  const queryParams: { [key: string]: string } = {};
  for (const [key, value] of parsedUrl.searchParams.entries()) {
    queryParams[camelcase(key)] = value;
  }

  return {
    pathname: parsedUrl.pathname,
    queryParams,
  };
}
