'use client';

/* eslint-disable-next-line no-restricted-imports */
import { useFlag, useFlagsStatus } from '@unleash/nextjs';
import { Flag } from './flags';

/**
 * Wrapper around useFlag to avoid hydration errors
 *
 * Previously we used `useFlag` directly on our components. Checking `useFlagsStatus.flagsReady` removes Hydration errors.
 *
 * Context: https://www.notion.so/kaligo/Hydration-Errors-1953440372d24b22900b191b7bcac176, https://kaligo.atlassian.net/browse/BUG-4290
 * @param {Flag} flagName the flag name to check
 * @returns {boolean} a boolean indicating if the feature flag is enabled
 */
export function useFlagsReady(flagName: Flag) {
  const { flagsReady } = useFlagsStatus();
  const flag = useFlag(flagName);

  return flagsReady && flag;
}
