'use client';
import { useContext } from 'react';
import { AuthContext } from './auth-context';
import { authStateEnum } from './auth-type';

export function useHermes() {
  const authContext = useContext(AuthContext);

  return {
    idleRemainingSeconds: authContext?.getIdleDialogRemainingSeconds,
    login: authContext?.login,
    logout: authContext?.logout,
    onIdleDialogClosed: authContext?.onIdleDialogClosed,
    scope: authContext?.decodedAccessToken?.scope.split(' ') ?? [],
    loggedIn: authContext?.authState === authStateEnum.enum.login,
    accessToken: authContext?.accessToken,
    pointsAccountId: authContext?.userPreference?.pointsAccountId,
    user: authContext?.user,
    authState: authContext?.authState,
    isOpenSessionExpiryModal: authContext?.isOpenSessionExpiryModal,
    setIsOpenSessionExpiryModal: authContext?.setIsOpenSessionExpiryModal,
    isImpersonated: authContext?.user?.act,
    readOnly: !!authContext?.decodedAccessToken?.read_only,
  };
}
