'use client';

import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';

interface FavIconProps {
  favicon: string;
}

export function FavIcon({ favicon }: FavIconProps) {
  const tenantHubConfig = useTenantHubConfig();
  const tenantHubIcon =
    tenantHubConfig?.favicon_url ?? tenantHubConfig?.logo_url;

  return (
    <link rel="icon" type="image/png" href={tenantHubIcon ?? favicon}></link>
  );
}
