import { IconStyle } from '@/config-schema/rewards-central-config'; 
export const fontawesomeIconMap: Map<IconStyle, string> = new Map(); 
fontawesomeIconMap.set('duotone', '/assets/icon/sprites/output/duotone.46fe26.svg'); 
fontawesomeIconMap.set('thin', '/assets/icon/sprites/output/thin.200267.svg'); 
fontawesomeIconMap.set('light', '/assets/icon/sprites/output/light.d8044f.svg'); 
fontawesomeIconMap.set('regular', '/assets/icon/sprites/output/regular.f341b6.svg'); 
fontawesomeIconMap.set('solid', '/assets/icon/sprites/output/solid.ba40d4.svg'); 
fontawesomeIconMap.set('sharp-duotone-solid', '/assets/icon/sprites/output/sharp-duotone-solid.270ddc.svg'); 
fontawesomeIconMap.set('sharp-light', '/assets/icon/sprites/output/sharp-light.d480d1.svg'); 
fontawesomeIconMap.set('sharp-regular', '/assets/icon/sprites/output/sharp-regular.14bae4.svg'); 
fontawesomeIconMap.set('sharp-solid', '/assets/icon/sprites/output/sharp-solid.e9c464.svg'); 
fontawesomeIconMap.set('sharp-thin', '/assets/icon/sprites/output/sharp-thin.8941a6.svg')