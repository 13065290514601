import { z } from 'zod';

export const staticDocumentCategorySchema = z.enum([
  'privacy_policy',
  'terms_conditions',
  'service_terms',
  'marketing_preferences',
]);

export const staticDocumentSchema = z.object({
  id: z.string().uuid(),
  category: staticDocumentCategorySchema,
  content: z.string(),
  format: z.enum(['markdown', 'html']),
  type: z.literal('static_document'),
});

export type StaticDocument = z.infer<typeof staticDocumentSchema>;

export type StaticDocumentCategory = z.infer<
  typeof staticDocumentCategorySchema
>;

export const staticDocumentsArrayResponseSchema = z.object({
  data: z.array(staticDocumentSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export type StaticDocumentArrayApiResponse = z.infer<
  typeof staticDocumentsArrayResponseSchema
>;
