import { useBootstrapData } from '@/store/store';
import { DateFormatter } from '@internationalized/date';
import { useLocale } from 'next-intl';
import { DateFormatterOptions } from 'react-aria';
import { getHourCycleFromLocale } from './get-hour-cycle-from-locale';
export interface FormatterOption<T extends string> {
  name: T;
  formatOption?: DateFormatterOptions;
}

type DateTimeFormatter<T extends string> = Record<
  `${T}Formatter`,
  DateFormatter
>;

export function useDateTimeFormatter<T extends string>(
  formatterOptions: FormatterOption<T>[],
): DateTimeFormatter<T> {
  const locale = useLocale();
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const formatters = {} as DateTimeFormatter<T>;
  const hourCycleFromLocale = getHourCycleFromLocale(locale);
  const hourCycleFromBootstrap = useHourCycleFromBootstrap();
  const hourCycle = hourCycleFromLocale ?? hourCycleFromBootstrap;

  for (const { name, formatOption } of formatterOptions) {
    const updatedFormatOption = hourCycle
      ? { ...formatOption, hourCycle }
      : formatOption;
    formatters[`${name}Formatter`] = new DateFormatter(
      locale,
      updatedFormatOption,
    );
  }

  return formatters;
}

function useHourCycleFromBootstrap() {
  const bootstrapData = useBootstrapData();
  const isStandardTime = bootstrapData?.timeFormat === '12_hour_clock';
  const hourCycle: DateFormatterOptions['hourCycle'] = isStandardTime
    ? 'h12'
    : 'h23';
  return hourCycle;
}
