'use client';

import {
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';

import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { getErrorsMessage } from '@/utils/get-nn-errors';
import { WontFix } from 'global';

export function RCQueryClientProvider(props: { children: React.ReactNode }) {
  const { sentryLog } = useSentryMonitoring();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // do not retry by default
            retry: false,
            // do not refetch on network reconnect by default
            // since it may affect form data
            // new data => form values are reset
            // we still can enable it manually for individual queries if needed
            refetchOnReconnect: false,
            // do not refetch on window focus by default
            // same reason as above
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: async (error: WontFix, query: Query) => {
            const errorMessage = await getErrorsMessage(error);
            const logMessage = [
              `Error while fetching from useQuery - ${JSON.stringify(
                query.queryKey,
              )}`,
              `Message: ${errorMessage}`,
            ].join('\n');

            sentryLog.error(logMessage);
          },
        }),
      }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {props.children}
    </QueryClientProvider>
  );
}
