'use client';

import { CSSProperties, type SVGProps } from 'react';

import { fontawesomeIconMap } from '@/asset-hash';
import { IconStyle } from '@/config-schema/rewards-central-config';
import { SupportedIconName } from '@/icons/supported-icon';
import { useCustomizationConfigStore } from '@/store/store';
import { cn } from '@/utils/tailwind';

// TODO: work with the design team to support icon size scale
const sizeClassName = {
  font: 'w-[1em] h-[1em]',
  xs: 'w-3 h-3',
  sm: 'w-4 h-4',
  md: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-7 h-7',
} as const;

type Size = keyof typeof sizeClassName;
// const childrenSizeClassName = {
//   font: 'gap-1.5',
//   xs: 'gap-1.5',
//   sm: 'gap-1.5',
//   md: 'gap-2',
//   lg: 'gap-2',
//   xl: 'gap-3',
// } satisfies Record<Size, string>;

type IconName = string;

// we copied and the source code here and modified it https://github.com/epicweb-dev/epic-stack/blob/main/docs/icons.md
/**
 * TODO: investigate: Renders an SVG icon. The icon defaults to the size of the font. To make it
 * align vertically with neighboring text, you can pass the text as a child of
 * the icon and it will be automatically aligned.
 * Alternatively, if you're not ok with the icon being to the left of the text,
 * you need to wrap the icon and text in a common parent and set the parent to
 * display "flex" (or "inline-flex") with "items-center" and a reasonable gap.
 */

/*
 * Requirements:
 * - Use Font Awesome icons
 * - Best performance approach: use SVG sprite and custom script to include needed icons
 * - Support multiple icon styles: solid, regular, light, sharp-solid, sharp-regular, sharp-light, duotone, thin
 * - Type safe: only allow supported icons are being used
 * <Icon name="a-unsupported-icon"> <= would be a compile error
 * - Support multiple icon sizes: font, xs, sm, md, lg, xl. This is waiting for the design team specs
 */

/**
 * Display icon by using icon sprite for the best performance.
 *
 * Add new icon name to the supported-icon.tsx file
 * the automation script will generate the icon sprite for you
 */
export function Icon({
  name,
  href,
  fill,
  // size = 'font',
  className,
  useDuoTone,
  // children,
  ...props
}: SVGProps<SVGSVGElement> & {
  name: SupportedIconName;
  href?: string;
  useDuoTone?: boolean;
  // size?: Size;
}) {
  // TODO: evaluate if we need to support children
  // if (children) {
  //   return (
  //     <span
  //       className={`inline-flex items-center ${childrenSizeClassName[size]}`}
  //     >
  //       <Icon name={name} size={size} className={className} {...props} />
  //       {children}
  //     </span>
  //   );
  // }

  // Design decision:
  // Technically we can make icon as a server component but it would not make sense when the consumer of Icon is a client component. and Icon component need to be used in both server and client component so Icon must be a client component.
  // That's why we use the client hook here
  let iconStyle = useCustomizationConfigStore(
    (state) => state.bootstrapData?.uiSettings.application.theme.iconStyle,
  );

  // TODO remove this when we migrated fa-solid to solid
  if (iconStyle?.startsWith('fa-')) {
    // This is expected
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    iconStyle = iconStyle.slice(3) as IconStyle;
  }

  const iconHref =
    href ||
    fontawesomeIconMap.get(useDuoTone ? 'duotone' : iconStyle ?? 'solid') ||
    '';

  return Boolean(iconHref) ? (
    <svg
      aria-hidden="true"
      className={cn('inline h-[1em] w-[1em] self-center', className)}
      {...props}
      fill={fill ?? 'currentColor'}
      // --fa-primary-color and --fa-secondary-color are not defined as part of CSS properties
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      style={
        useDuoTone
          ? ({
              '--fa-primary-color': 'var(--rc-color-primary-500)',
              '--fa-secondary-color': 'var(--rc-color-primary-200)',
            } as CSSProperties)
          : undefined
      }
      // className={cn(sizeClassName[size], 'inline self-center', className)}
    >
      <use href={`${iconHref}#${name}`} />
    </svg>
  ) : (
    <></>
  );
}
