export function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05026 5.63599L18.364 16.9497L16.9498 18.3639L5.63605 7.0502L7.05026 5.63599Z"
        fill="#05A0D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9498 5.63599L18.364 7.0502L7.05026 18.3639L5.63605 16.9497L16.9498 5.63599Z"
        fill="#05A0D1"
      />
    </svg>
  );
}
