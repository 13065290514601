'use client';

import type { DecodedAccessToken } from '@kaligo/hermes';
import type { UserPreference } from '@kaligo/hermes/dist/lib/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { AuthService } from '@/auth/auth-service';
import { authStateEnum, type AuthState } from '@/auth/auth-type';
import type { User } from '@/auth/schema';
import { defaultLocaleConfig } from '@/i18n/contexts/locale-config-context/constants';
import type { LocaleConfig } from '@/i18n/contexts/locale-config-context/types';

type AuthProviderAction =
  | {
      type: 'SET_AUTH_INFO';
      payload: {
        userPreference: UserPreference;
        user: User;
        accessToken: string;
        decodedAccessToken: DecodedAccessToken;
      };
    }
  | {
      type: 'SET_AUTH_SERVICE';
      payload: AuthService | null;
    }
  | {
      type: 'SET_AUTH_STATE';
      payload: AuthState;
    }
  | {
      type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL';
      payload: boolean;
    }
  | {
      type: 'SET_USER_LOCALE_CONFIG';
      payload: LocaleConfig;
    };

type AuthProviderState = {
  user: User | null;
  accessToken: string | null;
  decodedAccessToken: DecodedAccessToken | null;
  userPreference: UserPreference | null;
  authService: AuthService | null;
  authState: AuthState;
  isOpenSessionExpiryModal: boolean;
  localeConfig: LocaleConfig;
};

const initialState: AuthProviderState = {
  user: null,
  accessToken: null,
  decodedAccessToken: null,
  userPreference: null,
  authService: null,
  authState: authStateEnum.enum.default,
  isOpenSessionExpiryModal: false,
  localeConfig: defaultLocaleConfig,
};

function authProviderReducer(
  state: AuthProviderState,
  action: AuthProviderAction,
): AuthProviderState {
  switch (action.type) {
    case 'SET_AUTH_INFO': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_AUTH_SERVICE': {
      return {
        ...state,
        authService: action.payload,
      };
    }
    case 'SET_AUTH_STATE': {
      return {
        ...state,
        authState: action.payload,
      };
    }
    case 'SET_IS_OPEN_SESSION_EXPIRY_MODAL': {
      return {
        ...state,
        isOpenSessionExpiryModal: action.payload,
      };
    }
    case 'SET_USER_LOCALE_CONFIG': {
      return {
        ...state,
        localeConfig: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

interface AuthStore {
  state: AuthProviderState;
  dispatch: (action: AuthProviderAction) => void;
}
// Define your store
export const useAuthStore = create<AuthStore>()(
  devtools(
    (set) => ({
      state: initialState,
      dispatch: (action: AuthProviderAction) => {
        return set((authStore: AuthStore) => {
          const newState = authProviderReducer(authStore.state, action);
          return {
            ...authStore,
            state: newState,
          };
        });
      },
    }),
    { name: 'authStore' },
  ),
);

export function useUser() {
  return useAuthStore((state) => state.state.user);
}

export function useLocale() {
  return useAuthStore((state) => state.state.localeConfig.locale);
}
