'use client';
import { IneligibleRedeemErrorDialog } from '@/components/ineligible-redeem-dialog';
import { createContext, ReactNode, useState } from 'react';

export const IneligibleRedeemErrorContext = createContext({
  error: false,
  setError: (opened: boolean) => {},
});

export function IneligibleRedeemErrorProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [error, setError] = useState(false);
  return (
    <IneligibleRedeemErrorContext.Provider value={{ error, setError }}>
      <>
        <IneligibleRedeemErrorDialog
          open={error}
          onClose={() => {
            setError(false);
          }}
        />
        {children}
      </>
    </IneligibleRedeemErrorContext.Provider>
  );
}
