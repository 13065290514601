'use client';

import type { RouteParams } from '@/app/(types)/types';
import type { PropsWithChildren } from 'react';
import { I18nProvider as ReactAriaProvider } from 'react-aria';

interface Props extends PropsWithChildren, Pick<RouteParams, 'locale'> {}

export function I18nProvider({ children, locale }: Props) {
  return <ReactAriaProvider locale={locale}>{children}</ReactAriaProvider>;
}
