import * as z from 'zod';

/**
 * validate the data against the zod schema
 * @returns
 * if success: return parsed data. if error: return the original data. That's mean the zod data doesn't work in this case
 */
export function validateSchema<Output>({
  schemaName,
  zodSchema,
  data,
  errorCallback,
  hideError,
}: {
  schemaName: string;
  zodSchema: z.ZodSchema<Output>;
  data: unknown;
  errorCallback?: (zodError: unknown | z.ZodError) => {};
  hideError?: boolean;
}): Output {
  const result = zodSchema.safeParse(data);
  if (result.success) {
    return result.data;
  }
  errorCallback && errorCallback(result.error);
  // only log errors in dev to avoid noisy logs in graylog
  if (process.env.NODE_ENV !== 'production') {
    const errorLabel = `ZodError: Schema validation for "${schemaName}" api`;
    // TODO: report Sentry

    if (!hideError) {
      console.error(errorLabel, JSON.stringify(result.error.issues));
    }
  }

  // TODO: we need to cast this data to Output type
  // cause this function only validate the data so we can
  // safely cast it to Output type
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return data as Output;
}
