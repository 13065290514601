import { RcLink } from '@/components/rc-link';
import { cn } from '@/utils/tailwind';
import { linkClasses } from '../constants';
import { footerLinkDisplayName } from './constants';
import type { FooterLinkProps } from './types';

export function FooterLink({ className, children, ...props }: FooterLinkProps) {
  return (
    <RcLink className={cn(linkClasses, className)} {...props}>
      {children}
    </RcLink>
  );
}

FooterLink.displayName = footerLinkDisplayName;
