'use client';

import { useTranslations } from 'next-intl';

import { Container } from '@/components/container';
import { RcLink } from '@/components/rc-link';
import { usePathname } from '@/i18n/navigation';
import { cn } from '@/utils/tailwind';

export function BypassBlock() {
  const currentPage = usePathname();
  const t = useTranslations('bypassBlock');

  const skipToMainContentText = t('skipToMainContent');

  return (
    <Container className="w-full">
      <RcLink
        className={cn(
          'absolute translate-x-[-9999px]',
          'focus:inline-block focus:translate-x-0 focus:bg-primary',
          'z-[9] mt-[96px] font-bold',
          'px-12 py-4 text-white',
        )}
        href={`${currentPage}#main-content`}
      >
        {skipToMainContentText}
      </RcLink>
    </Container>
  );
}
