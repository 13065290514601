import { cn } from '@/utils/tailwind';
import { cva } from 'class-variance-authority';

// move the styling config to a separate file
// to make it easier to refactor the button details implementation

export const borderOutlineCva = cva('', {
  variants: {
    border: {
      true: '',
    },
    outline: {
      true: 'outline-none ring-[3px] ring-offset-0 ring-secondary',
    },
  },
});

const baseClasses = cn(
  'font-medium inline-flex border-primary text-sm font-bold outline-none transition-colors',
  // focus ring
  'data-[focus-visible]:ring-accent data-[focus-visible]:outline-none data-[focus-visible]:ring-[3px] data-[focus-visible]:ring-offset-0',
  'focus:outline-none focus:ring-[3px] focus:ring-secondary focus:ring-offset-0',
  // disable styles
  // TODO: not sure why cursor doesn't work
  'disabled:cursor-not-allowed',
  // TODO: migrate to use the typography component?
  'text-[16px] leading-[24px] lg:leading-[26px]',
);

const secondary = cn(
  'border-primary bg-white text-primary',
  // border configuration
  'secondary-button-border-width',
  'rounded-button-secondary',
  // hovered
  'data-[hovered]:bg-primary-200',
  // 'hover:bg-primary-200',
  // pressed
  'data-[pressed]:bg-primary-300',
  // disabled
  'disabled:border-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400',
  // Radio styles
  'data-[state=checked]:bg-primary data-[state=checked]:font-bold data-[state=checked]:text-white',
  'data-[state=checked]:hover:bg-primary-400',
  'hover:bg-primary-200 hover:disabled:bg-neutral-200',
);

const primary = cn(
  'border-primary bg-primary text-white',
  // border configuration
  'primary-button-border-width',
  'rounded-button-primary',
  // hover
  // 'hover:bg-primary-400',
  'data-[hovered]:border-primary-400 data-[hovered]:bg-primary-400',
  // pressed
  'data-[pressed]:border-primary-600 data-[pressed]:bg-primary-600',
  // disabled
  'disabled:border-primary-300 disabled:bg-primary-300 disabled:text-primary-200',
);

const subtle = cn(
  'text-neutral-600',
  // border configuration
  'border-none',
  'rounded-button-primary',
  // hover
  'data-[hovered]:bg-neutral-300',
  // pressed
  'data-[pressed]:bg-neutral-400',
  // disabled
  'disabled:text-primary-200',
);

// TODO: handle active (pressed state) once designer provide the spec
export const buttonVariants = cva(baseClasses, {
  variants: {
    variant: {
      secondary,
      primary,
      noBackground: cn(
        'rounded text-neutral',
        'hover:text-neutral-400',
        // disabled
        'disabled:text-neutral-300',
      ),
      subtle,
    },
    size: {
      // button size depends on padding and line height
      large: 'px-12 py-4',
      md: 'px-6 py-2',
      pagination:
        'p-0 h-8 w-8 justify-center items-center font-normal tabular-nums',
      radio: 'px-6 py-3 tabular-nums',
      icon: 'w-5 h-5 justify-center items-center',
    },
    icon: {
      // reset the padding for icon only variant
      iconOnly: 'p-0 justify-center items-center',
      iconLeft: 'justify-center items-center gap-2',
      iconRight: 'justify-between items-center gap-2',
    },
    fullWidth: {
      mobile: 'w-full lg:w-auto justify-center',
      desktop: 'lg:w-full',
      always: 'w-full',
    },
    textCenter: {
      true: 'justify-center',
    },
    isLoading: {
      true: 'justify-center item-center',
    },
    noBorder: {
      // We must use border-none instead of border-0 here
      // because tailwind-merge can not dedpe border-button-primary - our custom border width config
      true: 'border-none',
    },
    isSticky: {
      true: 'rounded-none border-none',
    },
    customPadding: {
      true: '',
    },
  },
  compoundVariants: [
    // fixed height/width for icon only variant
    {
      size: 'large',
      icon: 'iconOnly',
      className: 'h-[56px] w-[56px] lg:w-[58px] lg:h-[58px]',
    },
    {
      size: 'md',
      icon: 'iconOnly',
      className: 'h-[40px] w-[40px] lg:w-[42px] lg:h-[42px]',
    },
    {
      size: 'large',
      customPadding: true,
      className: 'custom-button-large-padding',
    },
    {
      size: 'md',
      customPadding: true,
      className: 'custom-button-medium-padding',
    },
  ],
  defaultVariants: {
    variant: 'secondary',
    size: 'large',
    fullWidth: undefined,
  },
});

// Large and medium variants size
// width and height of the button
// - [x] remove the fixed height and use typography to handle the height instead + set vertical padding
// vertical padding
// - [x] large 16px top/bottom
// - [x] md: 8px top/bottom
// --------
// - [ ] horizontal padding
// large
// 48px left/right padding
// small
// 24px left/right padding
// --------
// - [x] icon only
// fixed width/height
// --------
// - [ ] left icon
// 4px gap between icon and text

// --------
// - Right icon
// 4px gap between icon and text
// large
// 32px/24px left/right padding
// md
// 24px/16px left/right padding
// --------
// - Support new variant for pagination buttons
// - Support new variant for radio group styles
// - Support new variant for enroll now?? (link)
// - Support new variant for chips
// - For mobile consider button should always be full width of its container
// minimum top/left
// --------
// for numbers apply font-variant-numeric: tabular-nums;
// - number field should use the button
