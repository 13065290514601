'use client';

import { AppEnv } from '@/store/store';
import { useEffect } from 'react';
import { initSentry } from 'sentry.client.config';

export function SentryProvider({
  appEnv,
  appRevision,
  children,
}: {
  appEnv: AppEnv;
  appRevision?: string;
  children?: React.ReactNode;
}) {
  useEffect(() => {
    // only initialize Sentry on the client
    initSentry(appEnv, appRevision);
  }, [appEnv, appRevision]);

  return <>{children}</>;
}
