import { useMutation } from '@tanstack/react-query';

import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { Locale } from '@/i18n/types';
import { getClientUrl } from '@/utils/get-client-url';

export function useLocaleMutation() {
  const path = `${getClientUrl()}/api/user_preferences`;
  const authFetch = useAuthFetchKy;

  return useMutation({
    mutationFn: async (locale: Locale) => {
      return authFetch
        .put(path, {
          json: { user_preference: { locale_code: locale } },
        })
        .json();
    },
  });
}
