'use client';

import { useHermes } from '@/auth/use-hermes';
import { BootstrapData } from '@/schema/bootstrap.schema';
import { AppEnv } from '@/store/store';
import * as amplitude from '@amplitude/analytics-browser';
import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useEffect } from 'react';

const enabledEnvs = new Set<AppEnv>(['staging', 'production']);

interface AmplitudeProviderProps {
  appEnv: AppEnv;
  bootstrapData: BootstrapData;
}

function initSessionReplay() {
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);
}

// Set tenant user property on session start event
// https://community.amplitude.com/data-instrumentation-57/multi-tenancy-how-to-set-user-properties-before-tracking-events-alternatives-without-user-property-5070?tid=5070&postid=13450#post13450
const rewardsCentralUserPropertyPlugin = (tenant: string, environment: string): EnrichmentPlugin => {
  return {
    execute: async (event) => {
      event.user_properties = {
        ...event.user_properties,
        $setOnce: {
          app: 'rewards_central',
          platform: 'rc-us',
          environment,
          tenant,
          domain: 'client',
          tenantId: tenant // keep tenantId for backward compatibility
        },
      };
      return event;
    },
  };
};

function initAmplitude(appEnv: AppEnv, userId: string, tenantId: string) {
  const AMPLITUDE_ID =
    appEnv === 'production'
      ? '800b5aef550feb42e79d5f12084b1f2b'
      : 'f8d781d4545205ee9452129f64ec3c5a';

  if (!enabledEnvs.has(appEnv)) {
    return;
  }
  amplitude.init(AMPLITUDE_ID, {
    userId,
  });
}

export function AmplitudeProvider({
  appEnv,
  bootstrapData,
}: AmplitudeProviderProps) {
  const hermes = useHermes();

  useEffect(() => {
    const userId = hermes.user?.sub;

    if (!userId) {
      return;
    }

    amplitude.add(rewardsCentralUserPropertyPlugin(bootstrapData.tenantId, appEnv as string));
    initSessionReplay();
    initAmplitude(appEnv, userId, bootstrapData.tenantId);
  }, [appEnv, bootstrapData, hermes.user?.sub]);

  return null;
}
