export const GuardhouseErrorCodes = {
  INTERNAL: 'GH0000',
  TIMEOUT: 'GH0001',

  INVALID_TOKEN: 'GH1003',
  INVALID_TOKEN_SIGNATURE: 'GH1004',
  INVALID_TOKEN_EXPIRY: 'GH1005',
  UNAUTHORIZED_SCOPE: 'GH1010',
  INVALID_SCOPE: 'GH1011',
  NO_PERMISSION: 'GH2020',

  INVALID_APP_DOMAIN: 'GH2016',
  SESSION_EXPIRED: 'GH2022',
  REDIRECT_REQUIRED: 'GH2026',
} as const;

export const AlfredErrorCodes = {
  INVALID_FILTER_PARAMS: 'AF2001',
} as const;

const GH_TIMEOUT_RETRY_INTERVAL = 1000;
const GH_MAX_REQUEST_RETRIES = 3;

export const isAlfredInternalError = (errorCode?: string): boolean => {
  switch (errorCode) {
    case AlfredErrorCodes.INVALID_FILTER_PARAMS: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isGuardhouseInternalError = (errorCode: string): boolean => {
  switch (errorCode) {
    case GuardhouseErrorCodes.INTERNAL:
    case GuardhouseErrorCodes.INVALID_APP_DOMAIN: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isGuardhouseTokenError = (errorCode: string): boolean => {
  switch (errorCode) {
    // case GuardhouseErrorCodes.INVALID_TOKEN_EXPIRY:
    // this error is handled in another case, keep it here for reference
    case GuardhouseErrorCodes.INVALID_TOKEN:
    case GuardhouseErrorCodes.INVALID_TOKEN_SIGNATURE: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isGuardhouseScopeError = (errorCode: string): boolean => {
  switch (errorCode) {
    case GuardhouseErrorCodes.INVALID_SCOPE:
    case GuardhouseErrorCodes.UNAUTHORIZED_SCOPE: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export interface ErrorConfig {
  maxRetry: number;
  /**
   * start from 0
   */
  retryCount: number;
  xAuthErrors: Array<string>;
  responseErrors: Array<string>;
  httpStatus: number;
}
interface ErrorHandleResult {
  type:
    | 'error'
    | 'max-retry'
    | 'gh-scope-error'
    | 'timeout'
    | 'token-expired'
    | 'internal-failed'
    | 'no-permission';
}
export function handleNnApiError(config: ErrorConfig): ErrorHandleResult {
  // TODO: do we need to differentiate the maxRetry error and other error?
  if (config.retryCount >= config.maxRetry) {
    // report error
    return {
      type: 'max-retry',
    };
  }

  if (config.httpStatus === 401) {
    if (config.xAuthErrors && config.xAuthErrors.length > 0) {
      // handleGuardhouseAuthError
      // eslint-disable-next-line unicorn/prefer-ternary
      if (config.xAuthErrors.includes(GuardhouseErrorCodes.TIMEOUT)) {
        // action: retry
        return {
          type: 'timeout',
        };
      } else {
        // handleCommonAuthError
        return handleCommonAuthError(config.xAuthErrors);
      }
    } else {
      // handleSentinelAuthError
      const errorCodes = config.responseErrors;
      // eslint-disable-next-line unicorn/prefer-ternary
      if (
        errorCodes &&
        errorCodes.some((code) => isGuardhouseScopeError(code))
      ) {
        // TODO: InvalidScopeFail - report error
        return {
          type: 'gh-scope-error',
        };
      } else {
        return handleCommonAuthError(config.responseErrors);
      }
    }
  }

  return {
    type: 'error',
  };
}

function handleCommonAuthError(authErrorCodes: string[]): ErrorHandleResult {
  if (authErrorCodes.includes(GuardhouseErrorCodes.INVALID_TOKEN_EXPIRY)) {
    // TODO: TokenExpiredFail - wait for the next token refresh, waitForTokenRefresh
    return {
      type: 'token-expired',
    };
  } else if (
    authErrorCodes.some(
      (code) => isGuardhouseInternalError(code) || isGuardhouseTokenError(code),
    )
  ) {
    // TODO: InternalFail - handle internal error
    return {
      type: 'internal-failed',
    };
  } else if (authErrorCodes.includes(GuardhouseErrorCodes.NO_PERMISSION)) {
    // TODO: NoPermissionFail - show error modal
    return {
      type: 'no-permission',
    };
  }
  return {
    type: 'error',
  };
}
