const hourCycleMap = new Map<string, Intl.LocaleHourCycleKey>([
  ['en-US', 'h12'],
  ['en-GB', 'h12'],
  ['pt-BR', 'h12'],
  ['id-ID', 'h23'],
  ['es-ES', 'h23'],
  ['zh-HK', 'h12'],
  ['zh-CN', 'h12'],
]);

export function getHourCycleFromLocale(
  locale: string,
): Intl.LocaleHourCycleKey | undefined {
  return hourCycleMap.get(locale);
}
