import Drawer, {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { useTermsAndConditionsModalContent } from '@/hooks/static-documents/use-static-documents';
import { DialogTrigger } from 'react-aria-components';
import { MarketingTcModalProps } from '../terms-and-conditions-modal';

import { WontFix } from 'global';

import { useTranslations } from 'next-intl';
import { HTMLAttributes, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useHermes } from '@/auth/use-hermes';
import { Markdown } from '@/components/markdown';
import { Button } from '@/design-system-components/button/button';
import { Checkbox } from '@/design-system-components/checkbox/checkbox';
import { InfoContainer } from '@/design-system-components/info-container/info-container';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

export function MarketingConsentModal({
  isOpen,
  isSubmitError,
  onSubmit,
  isLoadingTcContent,
  data,
}: MarketingTcModalProps) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <MarketingConsentModalMobile
      data={data}
      isOpen={isOpen}
      isSubmitError={isSubmitError}
      isLoadingTcContent={isLoadingTcContent}
      onSubmit={onSubmit}
    />
  ) : (
    <MarketingConsentModalDesktop
      data={data}
      isOpen={isOpen}
      isSubmitError={isSubmitError}
      isLoadingTcContent={isLoadingTcContent}
      onSubmit={onSubmit}
    />
  );
}

export function MarketingConsentModalMobile({
  isOpen,
  isSubmitError,
  onSubmit,
  isLoadingTcContent,
  data,
}: MarketingTcModalProps) {
  const t = useTranslations('marketingConsentModal');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const somethingWentWrongText = t('somethingWentWrong');
  const marketingPreferencesText = t('marketingPreferences');

  return (
    <DialogTrigger isOpen={isOpen}>
      <Drawer>
        {({ close }) => {
          return (
            <>
              {isSubmitError ? (
                <InfoContainer variant="error" className="mb-4">
                  {somethingWentWrongText}
                </InfoContainer>
              ) : null}
              <DrawerHeader
                onClose={close}
                title={marketingPreferencesText}
              ></DrawerHeader>
              <DrawerBody
                disableScroll={true}
                className="flex flex-col overflow-hidden"
              >
                <div
                  ref={containerRef}
                  className="mt-4 flex-1 overflow-y-scroll break-words rounded-lg bg-neutral-200 p-4"
                >
                  <TcContent
                    isLoading={isLoadingTcContent}
                    data={data}
                  ></TcContent>
                </div>
              </DrawerBody>
              <DrawerFooter>
                <TcForm
                  isLoadingTcContent={isLoadingTcContent}
                  onSubmit={onSubmit}
                ></TcForm>
              </DrawerFooter>
            </>
          );
        }}
      </Drawer>
    </DialogTrigger>
  );
}

function MarketingConsentModalDesktop({
  data,
  isOpen,
  isSubmitError,
  onSubmit,
  isLoadingTcContent,
}: MarketingTcModalProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const t = useTranslations('marketingConsentModal');

  const somethingWentWrongText = t('somethingWentWrong');
  const marketingPreferencesText = t('marketingPreferences');

  return (
    <DialogTrigger isOpen={isOpen}>
      <Drawer
        desktopVariant="modal"
        className="items-center"
        modalClassName={cn(
          drawerModalClass.animation,
          'w-full rounded-lg bg-neutral-100 px-4 lg:p-10',
          'flex max-h-[calc(100vh-64px)] flex-col lg:max-w-[1008px]',
          'relative lg:fixed',
        )}
        dialogClassName="flex w-full flex-col"
        data-testid="ineligible-redeem-dialog"
        variant={'modal'}
      >
        {({ close }) => (
          <>
            {isSubmitError ? (
              <InfoContainer variant="error" className="mb-6">
                {somethingWentWrongText}
              </InfoContainer>
            ) : null}
            <div className="lg:12 mb-6 flex flex-col gap-6 lg:flex-row lg:items-center">
              <span
                className="text-heading-2"
                data-testid="ineligible-redeem-dialog-modal-title"
              >
                {marketingPreferencesText}
              </span>
            </div>
            <div className="flex flex-col overflow-hidden lg:flex-row">
              <div
                className={cn(
                  '"mt-4 flex flex-col overflow-hidden lg:mt-0 lg:flex-1',
                  // prevent the checkbox/button outline being cut off
                  'p-1',
                )}
              >
                <div
                  ref={containerRef}
                  className="mb-6 max-h-[550px] overflow-y-scroll rounded-lg bg-neutral-200 p-4"
                >
                  <TcContent
                    isLoading={isLoadingTcContent}
                    data={data}
                  ></TcContent>
                </div>
                <TcForm
                  isLoadingTcContent={isLoadingTcContent}
                  onSubmit={onSubmit}
                ></TcForm>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </DialogTrigger>
  );
}

const marketingTermId = 'tc-modal-marketing-terms-title';

function TcContent({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data: ReturnType<typeof useTermsAndConditionsModalContent>['data'];
}) {
  let content = '';

  if (isLoading || !data) {
    content = '';
    return (
      <div className="grid grid-cols-1 gap-2">
        {Array.from({ length: 20 }).map((_, index) => {
          return (
            <div
              key={index}
              className="h-5 animate-pulse rounded bg-neutral-400"
            ></div>
          );
        })}
      </div>
    );
  } else {
    // remark doesn't understand the self-closing tag here
    // it will wrap its below content in a div
    // add empty div here to support table of content scrolling
    content = `<div id="${marketingTermId}"> ${
      data.marketingPreferences?.content || ''
    } </div>`;
  }

  return (
    <Markdown
      overrides={{
        p: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: HTMLAttributes<HTMLParagraphElement>;
        }) => <p {...props} className="mb-2" />,
      }}
    >
      {content}
    </Markdown>
  );
}

function TcForm({
  isLoadingTcContent,
  onSubmit,
}: {
  isLoadingTcContent: boolean;
  onSubmit: MarketingTcModalProps['onSubmit'];
}) {
  const { isImpersonated, readOnly } = useHermes();
  const t = useTranslations('marketingConsentModal');
  const { formState, handleSubmit, control } = useForm({
    defaultValues: {
      marketingEmailConsent: false,
    },
  });

  const marketingEmailConsentText = t('marketingEmailConsent');
  const readOnlyModeText = t('readOnlyMode');
  const impersonatingText = t('impersonating');
  const acceptText = t('accept');

  return (
    <form
      onSubmit={handleSubmit(async ({ marketingEmailConsent }) => {
        return onSubmit({ marketingEmailConsent });
      })}
    >
      <div className="">
        <Controller
          name="marketingEmailConsent"
          control={control}
          rules={{ required: false }}
          render={({ field }) => {
            return (
              // TODO: move items-start and mt-[6px] to the checkbox component
              <Checkbox
                className="mt-[6px] pb-0"
                checkboxClassName={'lg:mt-0 lg:self-center'}
                id="marketing-consent-accept-checkbox"
                isSelected={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              >
                <span>{marketingEmailConsentText}</span>
              </Checkbox>
            );
          }}
        ></Controller>
      </div>
      {isImpersonated ? (
        <p className="mt-2 bg-error-200 p-4 text-error">
          {readOnly ? readOnlyModeText : impersonatingText}
        </p>
      ) : null}
      <div className="mt-8">
        <Button
          variant="primary"
          type="submit"
          isLoading={formState.isSubmitting}
          isDisabled={!formState.isValid || isLoadingTcContent}
        >
          {acceptText}
        </Button>
      </div>
    </form>
  );
}
