export function getClientUrl() {
  let host = '';

  if (typeof window !== 'undefined') {
    host = window.location.host;
  }

  if (host.includes('.kaligo-staging.xyz')) {
    host = host.replace('-dev.', '.');
  }

  return `https://${host}`;
}

export function getAuthRedirectUrl() {
  let host = '';

  if (typeof window !== 'undefined') {
    host = window.location.host;
  }

  return `https://${host}`;
}

export function getClientTenantName(): string {
  if (typeof window !== 'undefined') {
    let host = window.location.host.split('.')[0];
    host = host.replace('-dev', '');

    return host;
  }

  return 'called client function in server';
}
