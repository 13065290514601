import ky, { HTTPError } from 'ky';
import { z } from 'zod';

import { useAuthStore } from '@/app/auth-store';
import { handleNnApiError } from '@/hooks/fetch/handle-nn-error';
import { defaultLocale, symbolHeaderAcceptLanguage } from '@/i18n/constants';

export const nnErrorSchema = z.object({
  errors: z.array(z.object({ code: z.string(), message: z.string() })),
});

export type NnError = z.infer<typeof nnErrorSchema>;

export const useAuthFetchKy = ky.extend({
  // Retry only if 401
  retry: {
    limit: 3,
    methods: ['get', 'post', 'put', 'patch', 'delete'],
    statusCodes: [401],
    backoffLimit: 3000,
  },
  timeout: false,
  hooks: {
    beforeRequest: [
      (request) => {
        const authState = useAuthStore.getState().state;

        request.headers.set('X-Access-Token', String(authState.accessToken));

        /**
         * NOTE: This is a temporary solution to fix the issue with the Algolia API
         * where it does not allow the `X-Accept-Language` header.
         */
        if (
          /^(?!.*algolia).*$/i.test(request.url) &&
          authState.localeConfig.locale &&
          authState.localeConfig.defaultLocale
        ) {
          request.headers.set(
            symbolHeaderAcceptLanguage,
            [
              authState.localeConfig.locale,
              authState.localeConfig.defaultLocale,
              defaultLocale,
            ].join(', '),
          );
        }

        request.headers.set(
          // TODO: double check the NN team whether we still use
          // this local preference header when supporting multiple points account
          'x-rd-local-preferences',
          `points_account_id=${authState.userPreference?.pointsAccountId}`,
        );
      },
    ],
    beforeRetry: [
      async ({ request, options, error, retryCount }) => {
        if (error instanceof HTTPError) {
          // This logic assumes that this retry logic only used for GH authentication error we define above
          // other retry logic should be handled by react-query
          // refresh token before retry
          const originResponse = await error.response.json();
          const result = nnErrorSchema.safeParse(originResponse);

          if (!result.success) {
            // throw error if the response is not in the expected format
            // this behavior is not consistent between the local vs production/staging
            // since 401 error doesn't return the correct cors header for local
            // so the retry logic will never be executed in local
            console.error(
              'Error response is not in the expected format',
              result.error,
            );
            throw error;
          }

          const errorResponse = result.data;
          const xAuthErrors = safeExtractErrorCodeFromHeader(
            error.response.headers.get('x-auth-errors'),
          );
          const responseErrors = safeExtractErrorCode(errorResponse);

          const errorResult = handleNnApiError({
            maxRetry: 3,
            retryCount,
            xAuthErrors,
            responseErrors,
            httpStatus: error.response.status,
          });
          if (errorResult.type === 'timeout') {
            //
            return;
          }
          if (errorResult.type === 'token-expired') {
            // refresh token then retry
            const result = await useAuthStore
              .getState()
              .state.authService?.refreshTokenExchange();
            request.headers.set(
              'Authorization',
              `token ${result?.accessToken}`,
            );
          }

          // return undefined to retry
          return;
        }
        // stop retrying if the error is not HTTPError
        throw error;
      },
    ],
  },
});

export function safeExtractErrorCode(errorResponse: NnError): Array<string> {
  let errorCode: Array<string> = [];
  try {
    errorCode = errorResponse.errors.map((error) => error.code);
  } catch (error) {
    console.error('Cannot extract error codes from response', error);
  }
  return errorCode;
}

function safeExtractErrorCodeFromHeader(string: string | null): Array<string> {
  if (string) {
    return string.split(',');
  }
  return [];
}
