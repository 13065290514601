'use client';

import { useHermes } from '@/auth/use-hermes';
import { useIsAppEnvProduction } from '@/store/store';
import * as amplitude from '@amplitude/analytics-browser';
import { WontFix } from 'global';
import { useCallback } from 'react';

export interface AmplitudeCustomEventProperties {
  redemptionStep?: string;
  source?: string;
  rewardsSummaryStep?: string;
}

export interface AmplitudeTrackEventFunction<
  T extends Record<string, WontFix> = {},
> {
  (
    eventName: string,
    eventProperties?: T & AmplitudeCustomEventProperties,
  ): void;
}

export const useTrackAmplitudeEvent = <
  T extends Record<string, WontFix> = {},
>(): AmplitudeTrackEventFunction<T> => {
  const { isImpersonated } = useHermes();
  const isProduction = useIsAppEnvProduction();

  const trackAmplitudeEvent = useCallback<AmplitudeTrackEventFunction<T>>(
    (eventName, eventProperties) => {
      // Do not track events if the user is impersonated in production
      // but allow tracking in non-production environments to collect data
      if (isProduction && isImpersonated) {
        return;
      }

      amplitude.track(eventName, eventProperties);
    },
    [isImpersonated, isProduction],
  );
  return trackAmplitudeEvent;
};
