import { DecodedAccessToken } from '@kaligo/hermes';
import {
  HermesRtrOptions,
  IdleCheckOptions,
  RefreshTokenExchangeResult,
  UserPreference,
} from '@kaligo/hermes/dist/lib/types';
import { Todo, WontFix } from 'global';
import * as z from 'zod';

import { HermesConfigSchema, User } from './schema';

export type HermesConfig = z.infer<typeof HermesConfigSchema>;

export interface GuardhouseAddress {
  country?: string;
  formatted?: string;
  locality?: string;
  postalCode?: string;
  region?: string;
  streetAddress?: string;
  country_code?: string;
}

export const hermesErrorCodesEnum = z.enum(['HM09', 'HM07']);

export const hermesErrorCodes = {
  MISSING_REFRESH_TOKEN: hermesErrorCodesEnum.enum.HM09,
  INVALID_TOKEN: hermesErrorCodesEnum.enum.HM07,
} as const;

export const guardhouseErrorCodesEnum = z.enum([
  'GH0000',
  'GH0001',
  //
  'GH1003',
  'GH1004',
  'GH1005',
  //
  'GH1010',
  'GH1011',
  //
  'GH2020',
  //
  'GH2016',
  'GH2022',
  'GH2026',
  //
  'GH2027',
  'GH2030',
]);

export const guardhouseErrorCodes = {
  INTERNAL: guardhouseErrorCodesEnum.enum.GH0000,
  TIMEOUT: guardhouseErrorCodesEnum.enum.GH0001,

  INVALID_TOKEN: guardhouseErrorCodesEnum.enum.GH1003,
  INVALID_TOKEN_SIGNATURE: guardhouseErrorCodesEnum.enum.GH1004,
  INVALID_TOKEN_EXPIRY: guardhouseErrorCodesEnum.enum.GH1005,
  UNAUTHORIZED_SCOPE: guardhouseErrorCodesEnum.enum.GH1010,
  INVALID_SCOPE: guardhouseErrorCodesEnum.enum.GH1011,
  NO_PERMISSION: guardhouseErrorCodesEnum.enum.GH2020,

  INVALID_APP_DOMAIN: guardhouseErrorCodesEnum.enum.GH2016,
  SESSION_EXPIRED: guardhouseErrorCodesEnum.enum.GH2022,
  REDIRECT_REQUIRED: guardhouseErrorCodesEnum.enum.GH2026,

  MFA_REQUIRED: guardhouseErrorCodesEnum.enum.GH2027,
  SESSION_EXPIRED_REDIRECT: guardhouseErrorCodesEnum.enum.GH2030,
} as const;

export interface TransactionAppState {
  currentUrl?: string;
  // This is same as the defined type in @kaligo/hermes
  // so we will make it WontFix
  appState?: WontFix;
}

export const authStateEnum = z.enum(['default', 'login', 'logout']);
export type AuthState = z.infer<typeof authStateEnum>;

export const hermesServiceEventType = z.enum([
  'login',
  'update',
  'logout',
  'openSessionExpiredDialog',
  'closeSessionExpiredDialog',
]);

export type HermesServiceEventType = z.infer<typeof hermesServiceEventType>;

export type HermesServiceEvent =
  | {
      type: Exclude<HermesServiceEventType, 'login' | 'update'>;
    }
  | {
      type: Extract<HermesServiceEventType, 'login'>;
      payload: {
        accessToken: RefreshTokenExchangeResult['accessToken'];
        user: User;
        userPreference: UserPreference;
        transactionAppState?: TransactionAppState | null;
        decodedAccessToken: DecodedAccessToken;
      };
    }
  | {
      type: Extract<HermesServiceEventType, 'update'>;
      payload: {
        accessToken: RefreshTokenExchangeResult['accessToken'];
        user: User;
        userPreference: UserPreference;
        transactionAppState?: TransactionAppState | null;
        decodedAccessToken: DecodedAccessToken;
      };
    };

// RC only support RTR mode for now
// that's why we use HermesRtrOptions instead of HermesOptions
// export declare type HermesOptions = HermesIframeOptions | HermesRtrOptions;
export type HermesWrapperOptions = Omit<HermesRtrOptions, 'idleCheck'> & {
  idleCheck: Omit<IdleCheckOptions, 'onTimeout' | 'onActivityRestored'>;
} & {
  connection: string;
  onStateChange: (authState: HermesServiceEvent) => void;
  onError: (error: Todo) => void;
};

export const sessionExpiryDialogId = 'dialogElementId';
