import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

interface FooterRcLink {
  href: string;
  translation: string;
}

export function useFooterRcLinks() {
  const t = useTranslations('footer');

  const helpCenterText = t('helpCenter');
  const termsOfUseText = t('termsOfUse');
  const customerServiceSpecificTermsText = t('customerServiceSpecificTerms');
  const privacyPolicyText = t('privacyPolicy');

  const footerRcLinks = useMemo<FooterRcLink[]>(() => {
    return [
      { href: '/help-center', translation: helpCenterText },
      {
        href: '/help-center/terms-and-conditions',
        translation: termsOfUseText,
      },
      {
        href: '/help-center/customer-service-specific-terms',
        translation: customerServiceSpecificTermsText,
      },
      { href: '/help-center/privacy-policy', translation: privacyPolicyText },
    ];
  }, [
    customerServiceSpecificTermsText,
    helpCenterText,
    privacyPolicyText,
    termsOfUseText,
  ]);

  return footerRcLinks;
}
