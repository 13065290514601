import { useBootstrapData } from '@/store/store';
import { capitalizeFirstLetter } from '@/utils/text-utils';
import { useIsTenantHub } from '../config/tenants/use-is-tenanthub';
import { useTenantHubConfig } from '../config/tenants/use-tenanthub-config';

export function useTenantName() {
  const isTenantHubTenant = useIsTenantHub();
  const tenantHubConfig = useTenantHubConfig();

  const bootstrapData = useBootstrapData();
  const tenantName = bootstrapData?.loyaltyPrograms?.at(0)?.name;

  const companyName = tenantHubConfig?.company_name
    ? capitalizeFirstLetter(tenantHubConfig?.company_name)
    : tenantName;

  return isTenantHubTenant ? capitalizeFirstLetter(companyName!) : tenantName;
}
