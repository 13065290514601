import { EmblaOptionsType, EmblaPluginType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { usePrevNextButtons } from './arrow-button';
import { useDotButton } from './dot-button';

export function useCarousel(
  options?: EmblaOptionsType,
  plugins?: EmblaPluginType[],
) {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return {
    // Previous and next buttons
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,

    // Dot buttons
    onDotButtonClick,
    selectedIndex,
    scrollSnaps,

    emblaRef,
    emblaApi,
  };
}
