'use client';

import { captureException } from '@sentry/nextjs';
import { useFlagsStatus } from '@unleash/nextjs';
import { useEffect, type PropsWithChildren } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useAuthStore } from '@/app/auth-store';
import { localeConfigContext } from '@/i18n/contexts/locale-config-context/locale-config-context';
import { symbolHeaderRequestId } from '@/server/constants';
import type { LocaleConfig } from './types';

interface Props extends PropsWithChildren, LocaleConfig {
  host: string;
  requestId: string;
}

export function LocaleConfigProvider(props: Props) {
  const { children, host, requestId, ...value } = props;
  const { defaultLocale, locale, locales } = value;

  const dispatch = useAuthStore(useShallow((state) => state.dispatch));
  const flagStatus = useFlagsStatus();

  useEffect(() => {
    if (defaultLocale && locale && locales) {
      dispatch({
        type: 'SET_USER_LOCALE_CONFIG',
        payload: {
          defaultLocale,
          locale,
          locales,
        },
      });
    } else {
      captureException(
        new Error(
          [
            'Unable to update locale config after hydration due to missing required data.',
            `Expected 'defaultLocale' to be defined but received (${defaultLocale}).`,
            `Expected 'locale' to be defined but received (${locale}).`,
            `Expected 'locales' to be defined but received (${locales?.toString()}).`,
          ].join(' '),
        ),
        {
          tags: {
            'x-host': host,
            [symbolHeaderRequestId]: requestId,
          },
        },
      );
    }
  }, [defaultLocale, dispatch, host, locale, locales, requestId]);

  /**
   * NOTE(rongsen): TLDR; Do not render the children until the flags are ready.
   *
   * This is required to prevent all fetches from using the old locale config
   * before the new locale config is set. As the existing architecture does not
   * re-trigger all fetches when the locale config changes, we need to ensure
   * that the locale config is set before the children are rendered.
   *
   * FYI, this [JIRA ticket] will resolve the re-fetch issue.
   *
   * [JIRA ticket]: https://kaligo.atlassian.net/browse/RCF-1746
   */
  if (flagStatus.flagsReady) {
    return (
      <localeConfigContext.Provider value={value}>
        {children}
      </localeConfigContext.Provider>
    );
  }

  return null;
}
