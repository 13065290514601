'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';

import { authStateEnum, sessionExpiryDialogId } from '@/auth/auth-type';
import { useHermes } from '@/auth/use-hermes';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { Button } from '@/design-system-components/button/button';
import Drawer, {
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { cn } from '@/utils/tailwind';

export function SessionExpiryDialog() {
  const { isOpenSessionExpiryModal } = useHermes();
  return isOpenSessionExpiryModal ? <SessionExpiry></SessionExpiry> : null;
}

export function SessionExpiry() {
  const {
    idleRemainingSeconds,
    authState,
    isOpenSessionExpiryModal,
    logout,
    onIdleDialogClosed,
  } = useHermes();
  const [remainingTime, setRemainingTime] = useState<null | number>(0);

  useEffect(() => {
    if (!isOpenSessionExpiryModal && authState !== authStateEnum.enum.default) {
      return;
    }

    const countdownIntervalId = setInterval(check, 500);

    function check() {
      if (!idleRemainingSeconds) {
        return;
      }
      const time = idleRemainingSeconds();
      if (time) {
        setRemainingTime(time > 0 ? time : 0);
      }

      if (time && time <= 0) {
        console.log('DEBUG - auth: time out');
        clearInterval(countdownIntervalId);
        logout?.({
          isSessionExpired: true,
        });
      }
    }

    return () => {
      clearInterval(countdownIntervalId);
    };
  }, [
    idleRemainingSeconds,
    authState,
    logout,
    isOpenSessionExpiryModal,
    onIdleDialogClosed,
  ]);

  return (
    <SessionExpiryUi
      isOpen={!!isOpenSessionExpiryModal}
      remainingTimeInSeconds={remainingTime || 0}
      onClose={onIdleDialogClosed || (() => {})}
    ></SessionExpiryUi>
  );
}

export interface SessionExpiryUiProps {
  isOpen: boolean;
  remainingTimeInSeconds: number;
  onClose: () => void;
}

export function SessionExpiryUi(props: SessionExpiryUiProps) {
  const { isOpen, remainingTimeInSeconds, onClose } = props;

  const t = useTranslations('sessionExpiryDialog');

  const sessionExpiresText = t('sessionExpires');
  const logOutText = t.rich('logOut_wrapper_second', {
    /**
     * NOTE(sontruong):
     * remainingTimeInSeconds: number
     * no need to check nullish value for remainingTimeInSeconds
     * as it can't be null/undefined
     */
    wrapper: (chunks) => <span className="font-bold">{chunks}</span>,
    second: remainingTimeInSeconds,
  });
  const keepBrowsingText = t('keepBrowsing');

  return (
    <DialogTrigger isOpen={isOpen}>
      <Drawer
        desktopVariant="modal"
        className="flex items-center justify-center"
        modalClassName={cn(
          drawerModalClass.animation,
          drawerModalClass.dimensions,
          'lg-fixed relative',
        )}
        dialogClassName="flex w-full flex-col"
        data-testid="ineligible-redeem-dialog"
        variant="modal"
      >
        {({ close }) => (
          <>
            <div className="absolute right-4 top-5 lg:right-10">
              <RcLink
                href=""
                data-testid="drawer-close-button"
                onClick={() => {
                  close();
                  onClose();
                }}
              >
                <Icon className="h-5" name="xmark" />
              </RcLink>
            </div>
            <div
              className="mb-8 flex flex-col gap-6 lg:flex-row lg:items-center"
              id={sessionExpiryDialogId}
            >
              <span
                className="text-heading-2"
                data-testid="ineligible-redeem-dialog-modal-title"
              >
                {sessionExpiresText}
              </span>
            </div>
            <div className="mb-8">{logOutText}</div>
            <div className="flex justify-end">
              <Button
                variant="primary"
                className="mb-1 mr-1 px-12 py-4 focus:ring-0"
                fullWidth="mobile"
                onPress={() => {
                  onClose();
                }}
              >
                {keepBrowsingText}
              </Button>
            </div>
          </>
        )}
      </Drawer>
    </DialogTrigger>
  );
}
