import { FlightBookingTransactionErrors } from '@/schema/flights/booking-transaction/booking-transaction.schema';
import { WontFix } from 'global';
import { HTTPError } from 'ky';

export async function getErrorsMessage(error: WontFix) {
  let errorMessage = '';

  if (error instanceof Response) {
    if (error.headers.get('content-type')?.includes('application/json')) {
      const response = await error.json();
      errorMessage = JSON.stringify(response);
    } else {
      errorMessage = await error.text();
    }
  } else if (error instanceof HTTPError) {
    errorMessage = error.message;
  } else {
    errorMessage = JSON.stringify(error);
  }

  return errorMessage;
}

export async function getNNErrorsBody(error: Response) {
  const response: FlightBookingTransactionErrors = await error.json();
  return response;
}
