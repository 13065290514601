import * as Sentry from '@sentry/nextjs';
import * as Cookies from 'es-cookie';

import { z } from 'zod';
export const authStoragePrefix = 'rc.txs';

export function removeDuplicatedAuthCookie() {
  const allCookies = Cookies.getAll();
  const authCookieMap: Map<string, string> = new Map();
  // eslint-disable-next-line unicorn/no-array-for-each
  Object.keys(allCookies).forEach((key) => {
    if (key.startsWith(authStoragePrefix)) {
      authCookieMap.set(key, allCookies[key]);
    }
  });
  if (authCookieMap.size > 2) {
    Sentry.captureException('There are multiple auth cookies');
    // eslint-disable-next-line unicorn/no-array-for-each
    authCookieMap.forEach((value, key) => {
      Cookies.remove(key);
    });
  }
}

const perfLogSchema = z.object({
  label: z.string(),
  groupId: z.string(),
  dateTime: z.string(),
  timeDiff: z.number().optional(),
});

const perfLogKey = 'rc_perf_log';

function getPerfLogRawData() {
  if (typeof window === 'undefined') {
    return null;
  }
  const rcPerfLog = localStorage.getItem(perfLogKey);

  if (!rcPerfLog) {
    return [];
  }
  const parseResult = z.array(perfLogSchema).safeParse(JSON.parse(rcPerfLog));

  if (parseResult.success) {
    return parseResult.data;
  }

  return [];
}

export function getPerfLogData() {
  const rawData = getPerfLogRawData();
  if (!rawData) {
    return null;
  }

  if (rawData.length === 0) {
    return null;
  }

  rawData?.sort(
    (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime(),
  );

  // loop through the data and calculate the time difference between each log
  for (let i = 1; i < rawData.length; i++) {
    const prev = new Date(rawData[i - 1].dateTime);
    const current = new Date(rawData[i].dateTime);
    const diff = current.getTime() - prev.getTime();
    rawData[i].timeDiff = diff;
  }

  // time diff between the first and the last item
  const first = new Date(rawData[0].dateTime);
  const last = new Date(rawData[rawData.length - 1].dateTime);
  const diff = last.getTime() - first.getTime();

  return {
    total: diff,
    data: rawData,
  };
}

export function writePerfLog({
  label,
  groupId,
}: {
  label: string;
  groupId: string;
}) {
  if (typeof window === 'undefined') {
    return;
  }
  const perfData = getPerfLogRawData();

  if (!perfData) {
    return;
  }

  console.log('DEBUG  - perf:', label, groupId);
  perfData.push({
    dateTime: new Date().toISOString(),
    label,
    groupId,
  });
  localStorage.setItem(perfLogKey, JSON.stringify(perfData));
}

export function cleanPerfLog() {
  if (typeof window === 'undefined') {
    return;
  }
  localStorage.removeItem(perfLogKey);
}
