import { DateRangePickerDateFormat } from '@/design-system-components/date-picker/types';
import {
  CalendarDateTime,
  DateDuration,
  DateValue,
  getLocalTimeZone,
  isToday,
  toCalendarDate,
  today,
} from '@internationalized/date';
import {
  DatePickerProps,
  DateRange,
  DateRangePickerProps,
} from 'react-aria-components';

export type DateRangePickerConfig = Partial<DateRangePickerProps<DateValue>> & {
  dateFormat?: DateRangePickerDateFormat;
  value?: DateRange;
};

export type DatePickerConfig = Partial<DatePickerProps<DateValue>> & {
  dateFormat?: DateRangePickerDateFormat;
  value?: DateValue;
};

export const TODAY_DATE = today(getLocalTimeZone());
export const DATE_PICKER_MIN_DAY_OFFSET = 2;
export const DATE_PICKER_DEFAULT_MIN_DATE = TODAY_DATE.add({
  days: DATE_PICKER_MIN_DAY_OFFSET,
});
export const DATE_PICKER_DEFAULT_MAX_DATE_YEAR_OFFSET = 1;
export const DATE_PICKER_DEFAULT_MAX_DATE = TODAY_DATE.add({
  years: DATE_PICKER_DEFAULT_MAX_DATE_YEAR_OFFSET,
});

export const DATE_PICKER_NO_FOCUS_DATE_OFFSET: DateDuration = { days: 0 };
export const DATE_PICKER_ONE_DAY_FOCUS_DATE_OFFSET: DateDuration = { days: 1 };

export function toCalendarStateDate(date?: DateValue) {
  if (!date) return today(getLocalTimeZone());
  const { year, month, day } = date;

  return toCalendarDate(new CalendarDateTime(year, month, day));
}

export function isDateValueToday(date?: DateValue) {
  return isToday(toCalendarStateDate(date), getLocalTimeZone());
}
