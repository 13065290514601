'use client';
import { DecodedAccessToken, UserPreference } from '@kaligo/hermes';
import { createContext } from 'react';
import { AuthService } from './auth-service';
import { AuthState, HermesConfig } from './auth-type';
import { User } from './schema';

export type AuthContextConfig = Omit<HermesConfig, 'onLogin' | 'onLogout'>;

export const AuthContext = createContext<{
  accessToken: string | null;
  decodedAccessToken: DecodedAccessToken | null;
  user: User | null;
  userPreference: UserPreference | null;
  authState: AuthState;
  getIdleDialogRemainingSeconds: () => number | null;
  onIdleDialogClosed: () => void;
  login: () => void;
  logout: AuthService['authLogout'];
  isOpenSessionExpiryModal: boolean;
  setIsOpenSessionExpiryModal: (isOpen: boolean) => void;
} | null>(null);
