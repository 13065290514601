'use client';

import { useTranslations } from 'next-intl';
import { useContext } from 'react';

import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { Button } from '@/design-system-components/button/button';
import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import { useTenantId } from '@/hooks/utils/use-tenant-id';
import { useTenantName } from '@/hooks/utils/use-tenant-name';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';

// we should remove this logic out of this component if we start reusing this modal for purposes other than apple
const CARLTON_ONE_STOREFRONT_URL_STAGING =
  '/auth/saml/authorize?app_id=29e9be9f-faf4-46f0-9d21-3e63c5e5ea84';
const CARLTON_ONE_STOREFRONT_URL_UAT =
  '/auth/saml/authorize?app_id=ac74b62a-838b-4cf9-97d5-facd4c114ca5';
const CARLTON_ONE_STOREFRONT_URL_PROD =
  '/auth/saml/authorize?app_id=db844f1e-e77f-4467-acfb-a211aeab9af4';

let carltonOneStorefrontUrl = '';
switch (process.env.APP_ENV) {
  case 'staging': {
    carltonOneStorefrontUrl = CARLTON_ONE_STOREFRONT_URL_STAGING;
    break;
  }
  case 'uat': {
    carltonOneStorefrontUrl = CARLTON_ONE_STOREFRONT_URL_UAT;
    break;
  }
  case 'production': {
    carltonOneStorefrontUrl = CARLTON_ONE_STOREFRONT_URL_PROD;
    break;
  }
  default: {
    carltonOneStorefrontUrl = CARLTON_ONE_STOREFRONT_URL_STAGING;
  }
}

export default function RedirectModal() {
  const tenantName = useTenantName();

  // to support automation testing, this is temporary until SIT is done
  const tenantId = useTenantId();
  if (tenantId === 'qaautomation2') {
    carltonOneStorefrontUrl = '/auth/apple';
  }

  const t = useTranslations('redirectModal');
  const { setShowRedirectModal, showRedirectModal } =
    useContext(RedirectModalContext);

  const aboutToLeaveText = tenantName
    ? t('aboutToLeave_tenantName', { tenantName })
    : '';
  const redirectText = t('redirect');
  const stayHereText = t('stayHere');
  const proceedText = t('proceed');

  return (
    <Drawer
      isOpen={showRedirectModal}
      desktopVariant="modal"
      className="flex items-center"
      data-testid="redirect-modal-dialog"
      modalClassName={cn(
        '!duration-500 ease-out fill-mode-forwards',
        'rounded bg-white lg:w-[600px] lg:self-center lg:justify-self-center',
      )}
    >
      {({ close }) => {
        return (
          <>
            <DrawerHeader
              title={aboutToLeaveText}
              className="gap-1 lg:gap-0"
              onClose={() => {
                setShowRedirectModal(false);
                close();
              }}
            />
            <DrawerBody className="lg:pb-6">
              <p className="text-body">{redirectText}</p>
              <div className="my-6 flex flex-grow flex-col-reverse justify-end gap-4 lg:mb-0 lg:mt-8 lg:flex-row lg:gap-6">
                <Button
                  className="lg:block"
                  variant="secondary"
                  fullWidth="mobile"
                  data-testid="redirect-modal-close-button"
                  onPress={() => {
                    setShowRedirectModal(false);
                    close();
                  }}
                >
                  {stayHereText}
                </Button>
                {/* this submit action needs to be a link because we are redirecting to an external site on a new tab */}
                <RcLink
                  variant={RcLinkVariant.BUTTON}
                  className="lg:block"
                  data-testid="redirect-modal-proceed-button"
                  target="_blank"
                  href={carltonOneStorefrontUrl}
                  rel="noopener noreferrer"
                  prefetch={false}
                >
                  {proceedText}
                </RcLink>
              </div>
            </DrawerBody>
          </>
        );
      }}
    </Drawer>
  );
}
