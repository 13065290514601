import * as z from 'zod';

export enum TravelType {
  FLIGHT = 'flights',
  HOTEL = 'hotels',
  CAR = 'cars',
}

export const travelHotelMetadataSchema = z.object({
  productSubtype: z.literal('hotels'),
  endTime: z.string(),
  cashPaid: z.number(),
  lastName: z.string(),
  roomType: z.string(),
  firstName: z.string(),
  startTime: z.string(),
  productName: z.string(),
  currencyCode: z.string(),
  numberOfRooms: z.string(),
  cashPaidInUsd: z.number(),
  destinationName: z.string(),
  numberOfGuests: z.number(),
  travelConfirmationId: z.string(),
});

export const travelCarMetadataSchema = z.object({
  productSubtype: z.literal('cars'),
  endTime: z.string(),
  cashPaid: z.number(),
  lastName: z.string(),
  firstName: z.string(),
  startTime: z.string(),
  productName: z.string(),
  currencyCode: z.string(),
  pickupLocation: z.string(),
  returnLocation: z.string(),
  cashPaidInUsd: z.number(),
  travelConfirmationId: z.string(),
});

export const flightSegmentSchema = z.object({
  toAirport: z.string(),
  arrivalTime: z.string(),
  fromAirport: z.string(),
  departureTime: z.string(),
});

export const travelFlightMetadataSchema = z.object({
  productSubtype: z.literal('flights'),
  cashPaid: z.number(),
  passengers: z.array(
    z.object({
      lastName: z.string(),
      firstName: z.string(),
    }),
  ),
  cabinClass: z.string(),
  flightType: z.enum(['oneway', 'return']),
  productName: z.string(),
  currencyCode: z.string(),
  originSegment: flightSegmentSchema,
  returnSegment: flightSegmentSchema,
  cashPaidInUsd: z.number(),
  travelConfirmationId: z.string(),
});

export const travelMetadataSchema = z.discriminatedUnion('productSubtype', [
  travelHotelMetadataSchema,
  travelCarMetadataSchema,
  travelFlightMetadataSchema,
]);
